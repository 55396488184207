'use client';

import { useEffect } from 'react';

const ClientCookieSetter = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const utmSource = params.get('utm_source');

    const fbclid = params.get('fbclid');

    const expirationDate = new Date();

    expirationDate.setDate(expirationDate.getDate() + 60);

    const expires = `expires=${expirationDate.toUTCString()}; path=/`;

    if (utmSource) {
      console.log('UTM Source:', utmSource);

      document.cookie = `utm_source=${utmSource}; ${expires}`;
    }

    if (fbclid) {
      console.log('FBCLID:', fbclid);

      document.cookie = `fbclid=${fbclid}; ${expires}`;
    }
  }, []);

  return null;
};

export default ClientCookieSetter;
