import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/app/styles/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/portal/app/utils/ClientCookieSetter.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/icons/social/facebook-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/icons/social/instagram-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/icons/social/pinterest.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/portal/public/images/icons/social/twitter-icon.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Karla\",\"arguments\":[{\"subsets\":[\"latin\"],\"style\":[\"normal\",\"italic\"],\"weight\":[\"200\",\"300\",\"500\",\"400\",\"600\",\"700\",\"800\"],\"display\":\"swap\"}],\"variableName\":\"karla\"}");
